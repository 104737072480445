import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer} from '@angular/platform-browser'

@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) {
  }

  transform(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
}
